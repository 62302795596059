import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BasicPage from "../components/basicPage"


const content = `
<p>Thank you for visiting kosmique.com. We care about anybody visiting this website and are committed to facilitating and improving its accessibility and usability by ensuring that our website functionalities and content are accessible to persons with disabilities including, but not limited to, users of screen reader technology.</p>

<p>Please be aware that our efforts to improve accessibility and usability are ongoing. While we strive to make the site as accessible as possible, our content and pages may not always pass online testing or validation tools. We appreciate your understanding.</p>

<h2>Ongoing Monitoring</h2>
<p>Making our website accessible to all visitors is important to us and we will monitor the site and seek ways to improve the experience for everybody. If, at any time, you have specific questions or concerns about the accessibility of any particular page or functionality on this website, please contact us by e-mail at hello@kosmique.com.</p>

<h2>Accessibility Assistance</h2>
<p>If you do encounter an accessibility issue, please send a note to hello@kosmique.com. Please be sure to specify the web content and nature of the issue, and we will work with you to make the page or the information contained therein accessible for you in accordance with applicable law (for example, through email or phone support).</p>
`

const Accessibility = () => (
  <Layout>
    <Seo title="Accessibility" />
		<BasicPage title="Accessibility" content={content} />
  </Layout>
)

export default Accessibility
